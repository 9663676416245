import React, { Fragment } from 'react';
import { useNavigate } from 'react-router-dom';
import { useIntl } from 'react-intl';
import * as classnames from 'classnames';
import { AppContext } from '../../../storage/context';
import { postSaveCandidate, postContactCandidate } from '../../../api/services/User';
import { postTrackingEvent } from '../../../api/services/Tracking';
import { API_BASE_URL, LANGUAGE, LANGUAGES, PATHS, TRACKING_EVENT_NAMES } from '../../../utils/constants';
import useLoadingLayer from '../../../utils/hooks/useLoadingLayer';
import useSetError from '../../../utils/hooks/useSetError';
import Button36 from '../ui/Buttons/Button36';
import ToTopButton from '../ui/Buttons/ToTopButton';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faUser } from '@fortawesome/free-solid-svg-icons';
import './index.scss';

const CandidateDetails = (props) => {
  const [{ helpers = {}, user = {} }, dispatch] = React.useContext(AppContext);
  const { candidate, pageContentElement, sectionElements } = props;

  const navigate = useNavigate();
  const intl = useIntl();
  const loadingLayer = useLoadingLayer();
  const setError = useSetError();

  const { tools, skills, trackingEventTypes } = helpers;
  const selectedLanguageCode = localStorage.getItem(LANGUAGE) || LANGUAGES.DK;

  const saveCandidate = async () => {
    if (user?.id && candidate?.id) {
      try {
        loadingLayer.show();
        const response = await postSaveCandidate(user.id, candidate.id, setError);
        if (response?.message) {
          dispatch({
            type: 'setModalData',
            modalData: {
              title: null,
              description: intl.messages[response.message],
            },
          });
        }
      } catch(err) {
        console.error(err);
      } finally {
        loadingLayer.hide();
      }
    }
  };

  const contactCandidate = () => {
    if (user?.id && candidate?.id) {
      dispatch({
        type: 'setFullScreenPromptModalData',
        fullScreenPromptModalData: {
          titles: [
            {
              text: intl.messages['modal.contactCandidate.title1'],
              className: '-new-tertiary',
            },
            {
              text: intl.messages['modal.contactCandidate.title2'],
              className: '-white',
            },
          ],
          descriptions: [
            {
              text: intl.messages['modal.contactCandidate.description'],
              className: '-white',
            },
          ],
          option1: {
            label: intl.messages['modal.contactCandidate.option1Label'],
            handler: sendContactRequest,
          },
          option2: {
            label: intl.messages['modal.contactCandidate.option2Label'],
            handler: sendContactRequest,
          },
        }
      });
    }
  };

  const sendContactRequest = async (contactMethod) => {
    try {
      loadingLayer.show();
      const payload = {
        userId: user.id,
        candidateId: candidate.id,
        contactMethod,
      };

      const response = await postContactCandidate(payload, setError);
      if (response?.message) {
        dispatch({
          type: 'setModalData',
          modalData: {
            title: null,
            description: intl.messages[response.message],
          },
        });
      }
    } catch(err) {
      console.error(err);
    } finally {
      loadingLayer.hide();
    }
  };

  const scrollToTop = () => {
    pageContentElement.scroll(0, 0);
  };

  React.useEffect(() => {
    const trackPageVisit = async () => {
      const eventId = trackingEventTypes
        ?.find(eventType => eventType.name === TRACKING_EVENT_NAMES.CANDIDATE_DISPLAY)
        ?.id;
  
      if (user?.id && eventId && candidate?.id) {  
        try {
          loadingLayer.show();
  
          const payload = {
            userId: user.id,
            trackingEventTypeId: eventId,
            candidateId: candidate.id,
          };
  
          await postTrackingEvent(payload, setError);
        } catch (err) {
          console.error(err);
        } finally {
          loadingLayer.hide();
        }
      }
    };

    trackPageVisit();
  }, [user, candidate, loadingLayer, setError, trackingEventTypes]);

  return (
    <div className="candidate-details">
      <section
        className="candidate-details__section-profile"
        ref={sectionElements.profile}
        id="candidate-profile-section"
      >
        <div className="candidate-details__avatar-container">
          {candidate?.avatar ? (
            <div className="candidate-details__avatar">
              <img className="candidate-details__avatar-image" src={`${API_BASE_URL}${candidate.avatar}`} alt={candidate.profileName} />
            </div>
          ) : (
            <div className="candidate-details__avatar-placeholder">
              <FontAwesomeIcon icon={faUser} className="candidate-details__avatar-placeholder-image" />
            </div>
          )}
          <div className="candidate-details__buttons">
            <Button36
              className={classnames(
                'candidate-details__button',
                '-new-tertiary',
              )}
              label={intl.messages['common.save']}
              handleClick={saveCandidate}
            />
            <Button36
              className={classnames(
                'candidate-details__button',
                '-new-primary',
              )}
              label={intl.messages['common.getInTouch']}
              handleClick={contactCandidate}
            />
          </div>
        </div>
        <p
          className="candidate-details__profile-title"
          dangerouslySetInnerHTML={{ __html: candidate?.[selectedLanguageCode].profileTitle }}
        ></p>
        <p
          className="candidate-details__profile-description"
          dangerouslySetInnerHTML={{ __html: candidate?.[selectedLanguageCode].profileDescription }}
        ></p>
      </section>
      {candidate?.skillIds?.length ? (
        <section
          className="candidate-details__section-skills"
          ref={sectionElements.skills}
          id="candidate-skills-section"
        >
          <div className="candidate-details__skills-container">
            <h3
              className="candidate-details__segment-title"
              dangerouslySetInnerHTML={{ __html: intl.messages['candidate.experienceIn'] }}
            ></h3>
            {candidate.skillIds
            ?.map(skillId => skills?.find(skill => skill.id === skillId))
            .sort((skill1, skill2) => skill2.level - skill1.level)
            .map((skill, index, array) => (
              <Fragment key={index}>
                <div className="candidate-details__segment-skill">
                  <p
                    className="candidate-details__segment-skill-name"
                    dangerouslySetInnerHTML={{ __html: skill?.[selectedLanguageCode].name }}
                  ></p>
                </div>
                {index < array.length - 1 && (
                  <div className="candidate-details__segment-separator"></div>
                )}
              </Fragment>
            ))}
          </div>
          <div className="candidate-details__tools-container">
            <h3
              className="candidate-details__segment-title"
              dangerouslySetInnerHTML={{ __html: intl.messages['candidate.tools'] }}
            ></h3>
            {candidate.tools
              ?.map(tool => tools?.find(toolEntity => toolEntity.id === tool.toolId))
              .sort((tool1, tool2) => tool2.level - tool1.level)
              .map((tool, index, array) => (
                <Fragment key={index}>
                  <div className="candidate-details__segment-tool">
                    <p
                      className="candidate-details__segment-tool-name"
                      dangerouslySetInnerHTML={{ __html: tool?.name }}
                    ></p>
                  </div>
                  {index < array.length - 1 && (
                    <div className="candidate-details__segment-separator"></div>
                  )}
                </Fragment>
              ))}
          </div>
        </section>
      ) : null}
      {candidate?.references?.length ? (
        <section
          className="candidate-details__section-references"
          ref={sectionElements.references}
          id="candidate-reference-section"
        >
          <h3
            className="candidate-details__segment-title"
            dangerouslySetInnerHTML={{ __html: intl.messages['candidate.references'] }}
          ></h3>
          {candidate.references.map((reference, index, array) => (
            <Fragment key={index}>
              <h4
                className="candidate-details__segment-subtitle"
                dangerouslySetInnerHTML={{ __html: reference[selectedLanguageCode].header }}
              ></h4>
              <p
                className="candidate-details__segment-description"
                dangerouslySetInnerHTML={{ __html: reference[selectedLanguageCode].description }}
              ></p>
              {index < array.length - 1 && (
                <div className="candidate-details__segment-separator"></div>
              )}
            </Fragment>
          ))}
        </section>
      ) : null}
      {candidate?.education?.length ? (
        <section
          className="candidate-details__section-education"
          ref={sectionElements.education}
          id="candidate-education-section"
        >
          <h3
            className="candidate-details__segment-title"
            dangerouslySetInnerHTML={{ __html: intl.messages['candidate.education'] }}
          ></h3>
          {candidate.education.map((education, index, array) => (
            <Fragment key={index}>
              <h4
                className="candidate-details__segment-subtitle"
                dangerouslySetInnerHTML={{ __html: education[selectedLanguageCode].header }}
              ></h4>
              <p
                className="candidate-details__segment-description"
                dangerouslySetInnerHTML={{ __html: education[selectedLanguageCode].description }}
              ></p>
              {index < array.length - 1 && (
                <div className="candidate-details__segment-separator"></div>
              )}
            </Fragment>
          ))}
        </section>
      ) : null}
      {candidate?.certifications?.length ? (
        <section
          className="candidate-details__section-certifications"
          ref={sectionElements.certifications}
          id="candidate-certifications-section"
        >
          <h3
            className="candidate-details__segment-title"
            dangerouslySetInnerHTML={{ __html: intl.messages['candidate.certifications'] }}
          ></h3>
          {candidate.certifications.map((certification, index, array) => (
            <Fragment key={index}>
              <h4
                className="candidate-details__segment-subtitle"
                dangerouslySetInnerHTML={{ __html: certification[selectedLanguageCode].header }}
              ></h4>
              <p
                className="candidate-details__segment-description"
                dangerouslySetInnerHTML={{ __html: certification[selectedLanguageCode].description }}
              ></p>
              {index < array.length - 1 && (
                <div className="candidate-details__segment-separator"></div>
              )}
            </Fragment>
          ))}
        </section>
      ) : null}
      <section
        className="candidate-details__section-bottom"
        ref={sectionElements.bottom}
        id="candidate-bottom-section"
      >
        <p
          className="candidate-details__segment-description"
          dangerouslySetInnerHTML={{ __html: intl.messages['candidate.accessSavedCandidates'] }}
        ></p>
        <Button36
          className={classnames(
            'candidate-details__button-my-site',
            '-new-primary',
          )}
          label={intl.messages['common.mySite']}
          handleClick={() => { navigate(PATHS.profile) }}
        />
        <ToTopButton
          className={classnames(
            'candidate-details__button-to-top',
          )}
          handleClick={scrollToTop}
        />
        <p
          className="candidate-details__text-to-top"
          dangerouslySetInnerHTML={{ __html: intl.messages['candidate.toTop'] }}
          onClick={scrollToTop}
        ></p>
      </section>
    </div>
  );
};

export default CandidateDetails;
