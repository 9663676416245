const colors = {
    // ------------------ MAIN COLOR PALETTE ------------------ //

    primary: '#2A5B97',
    primaryDark: '#3B4661',
    primaryDarker: '#333B45',
    primaryLight: '#535E79',
    primaryLighter: '#A4A9B5',
    primaryLightest: '#F1F4F8',
    
    secondary: '#62B7E7',
    secondaryLightest: '#E9F5FC',
    
    tertiary: '#E2B867',
    tertiaryDark: '#D98703',
    
    // --------------------- GREY COLORS --------------------- //
    
    grey: '#9F9F9F',
    greyLight: '#ACACAC',
    greyLighter: '#CECECE',
    greyLightest: '#E8E8E8',
    greyDark: '#707070',
    greyDarker: '#464646',
    greyDarkest: '#232323',
    
    // --------------------- BASIC COLORS --------------------- //
    
    white: '#FFFFFF',
    whiteTransparent10: '#FFFFFF1A',
    black: '#000000',
    blackLight: '#101010',
    red: 'FF0000',
    redDark: '#9E200B',
    green: '#2DA808',
    
    // --------------------- NEW IETD COLORS --------------------- //

    newPrimary: '#3F5C57',
    newPrimaryLight: '#748985',
    newSecondary: '#3C3C3C',
    newTertiary: '#DDA01F',
    newGreyLight: '#F5F5F5',
};

export default colors;
