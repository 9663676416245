import React from 'react';
import { useIntl } from 'react-intl';
import { useNavigate } from 'react-router-dom';
import * as classnames from 'classnames';
import { AppContext } from '../../../storage/context';
import { API_BASE_URL, LANGUAGE, LANGUAGES, PATHS } from '../../../utils/constants';
import useLoadingLayer from '../../../utils/hooks/useLoadingLayer';
import useSetError from '../../../utils/hooks/useSetError';
import { postContactCandidate } from '../../../api/services/User';
import Button34 from '../ui/Buttons/Button34';
import CloseButton from '../ui/Buttons/CloseButton';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faUser } from '@fortawesome/free-solid-svg-icons';
import './index.scss';

const CandidateCardWide = (props) => {
  const [{ user = {}, helpers = {} }, dispatch] = React.useContext(AppContext);
  const { className, candidate, removeSavedCandidate } = props;

  const intl = useIntl();
  const navigate = useNavigate();
  const loadingLayer = useLoadingLayer();
  const setError = useSetError();

  const { tools, skills } = helpers;
  const selectedLanguageCode = localStorage.getItem(LANGUAGE) || LANGUAGES.DK;

  const contactCandidate = () => {
    if (user?.id && candidate?.id) {
      dispatch({
        type: 'setFullScreenPromptModalData',
        fullScreenPromptModalData: {
          titles: [
            {
              text: intl.messages['modal.contactCandidate.title1'],
              className: '-new-tertiary',
            },
            {
              text: intl.messages['modal.contactCandidate.title2'],
              className: '-white',
            },
          ],
          descriptions: [
            {
              text: intl.messages['modal.contactCandidate.description'],
              className: '-white',
            },
          ],
          option1: {
            label: intl.messages['modal.contactCandidate.option1Label'],
            handler: sendContactRequest,
          },
          option2: {
            label: intl.messages['modal.contactCandidate.option2Label'],
            handler: sendContactRequest,
          },
        }
      });
    }
  };

  const sendContactRequest = async (contactMethod) => {
    try {
      loadingLayer.show();
      const payload = {
        userId: user.id,
        candidateId: candidate.id,
        contactMethod,
      };

      const response = await postContactCandidate(payload, setError);
      if (response?.message) {
        dispatch({
          type: 'setModalData',
          modalData: {
            title: null,
            description: intl.messages[response.message],
          },
        });
      }
    } catch(err) {
      console.error(err);
    } finally {
      loadingLayer.hide();
    }
  };

  return (
    <div
      className={classnames(
        'candidate-card-wide',
        className && className,
      )}
    >
      <div className="candidate-card-wide__avatar-container">
        {candidate.avatar ? (
          <div className="candidate-card-wide__avatar">
            <img className="candidate-card-wide__avatar-image" src={`${API_BASE_URL}${candidate.avatar}`} alt={candidate.profileName} />
          </div>
        ) : (
          <div className="candidate-card-wide__avatar-placeholder">
            <FontAwesomeIcon icon={faUser} className="candidate-card-wide__avatar-placeholder-image" />
          </div>
        )}
      </div>
      <div className="candidate-card-wide__description-container">
        <p
          className="candidate-card-wide__profile-title"
          dangerouslySetInnerHTML={{ __html: candidate[selectedLanguageCode].profileTitle }}
        ></p>
        <p
          className="candidate-card-wide__profile-number"
          dangerouslySetInnerHTML={{ __html: candidate.profileNumber }}
        ></p>
        <p
          className="candidate-card-wide__profile-description"
          dangerouslySetInnerHTML={{ __html: candidate[selectedLanguageCode].profileDescription }}
        ></p>
      </div>
      <div className="candidate-card-wide__skills-container">
        <div className="candidate-card-wide__tools">
          {candidate.tools
          .map(tool => tools?.find(toolEntity => toolEntity.id === tool.toolId))
          .sort((tool1, tool2) => tool2.level - tool1.level)
          .map((tool, index) => (
            index < 2 && (
              <div key={index} className="candidate-card-wide__tool">
                <p
                  className="candidate-card-wide__tool-name"
                  dangerouslySetInnerHTML={{ __html: tool?.name }}
                ></p>
              </div>
            )
          ))}
        </div>
        <div className="candidate-card-wide__skills">
          {candidate.skillIds
          .map(skillId => skills?.find(skill => skill.id === skillId))
          .sort((skill1, skill2) => skill2.level - skill1.level)
          .map((skill, index) => (
            index < 3 && (
              <div key={index} className="candidate-card-wide__skill">
                <p
                  className="candidate-card-wide__skill-name"
                  dangerouslySetInnerHTML={{ __html: skill?.[selectedLanguageCode].name }}
                ></p>
              </div>
            )
          ))}
        </div>
      </div>
      <div className="candidate-card-wide__button-container">
        <CloseButton
          className="candidate-card-wide__close-button"
          handleClick={() => removeSavedCandidate(candidate.id)}
        />
        <div className="candidate-card-wide__main-buttons">
          <Button34
            className='candidate-card-wide__button -new-tertiary'
            label={intl.messages['common.goTo']}
            handleClick={() => navigate(`${PATHS.candidateBySearch}/${candidate.id}`)}
          />
          <Button34
            className='candidate-card-wide__button -new-primary'
            label={intl.messages['common.getInTouch']}
            handleClick={contactCandidate}
          />
        </div>
      </div>
    </div>
  );
};

export default CandidateCardWide;
