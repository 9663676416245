import React from 'react';
import { useIntl } from 'react-intl';
import { useNavigate } from "react-router-dom";
import {
  IETD,
  USERNAME,
  TOKEN,
  TWO_WEEKS_MS,
  EXP_DATE,
  PATHS,
  USER,
} from '../../../utils/constants';
import useLoadingLayer from '../../../utils/hooks/useLoadingLayer';
import useSetError from '../../../utils/hooks/useSetError';
import { AppContext } from '../../../storage/context';
import LoginForm from '../../forms/LoginForm';
import { postLogin } from '../../../api/services/Auth';
import './index.scss';

const LoginPage = () => {
  const dispatch = React.useContext(AppContext)[1];

  const intl = useIntl();
  const navigate = useNavigate();
  const loadingLayer = useLoadingLayer();
  const setError = useSetError();

  const localToken = sessionStorage.getItem(TOKEN) || '';

  const setToken = token => {
    dispatch({
      type: 'setToken',
      token,
    });
  };

  const setUser = user => {
    dispatch({
      type: 'setUser',
      user
    });
  };

  const login = async (values, { setSubmitting }) => {
    const loginData = {
      email: values.email,
      password: values.password,
    };

    try {
      loadingLayer.show();
      const { jwt, user } = await postLogin(loginData, setError, intl);
  
      if (jwt) {
        setToken(jwt)
        dispatch({ type: 'nullError', error: {} });
        sessionStorage.setItem(TOKEN, jwt);
        const expDate = new Date(Date.now() + TWO_WEEKS_MS).toISOString();
        sessionStorage.setItem(EXP_DATE, expDate);
        sessionStorage.setItem(USERNAME, loginData.name);
        navigate(PATHS.menu);
      }
      if (user) {
        setUser(user);
        sessionStorage.setItem(USER, JSON.stringify(user));
      }
    } catch(error) {
      console.error(error);
    } finally {
      loadingLayer.hide();
    }

    setSubmitting(false);
  };

  React.useEffect(() => {
    if (localToken) {
      navigate(PATHS.menu);
    }
  }, [navigate, localToken]);

  return (
    <div className="login-page">
      <div className="login-page__container">
        <h3
          className="login-page__welcome"
          dangerouslySetInnerHTML={{ __html: intl.messages['loginPage.welcome'] }}
        ></h3>
        <div className="login-page__title">
          <h1 className="login-page__title-front">{IETD}</h1>
        </div>
        <div className="login-page__descriptions">
          <p
            className="login-page__description"
            dangerouslySetInnerHTML={{ __html: intl.messages['loginPage.description1'] }}
          ></p>
          <p
            className="login-page__description"
            dangerouslySetInnerHTML={{ __html: intl.messages['loginPage.description2'] }}
          ></p>
          <p
            className="login-page__description"
            dangerouslySetInnerHTML={{ __html: intl.messages['loginPage.description3'] }}
          ></p>
        </div>
        <LoginForm onSubmit={login} />
       
      </div>
    </div>
  );
};

export default LoginPage;
