import React from 'react';
import { useNavigate } from 'react-router-dom';
import { IETD, PATHS } from '../../../utils/constants';
import { BREAKPOINTS } from '../../../utils/breakpoints';
import ScreenResolver from '../ScreenResolver'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faBars, faUser } from '@fortawesome/free-solid-svg-icons';
import './index.scss';

const TopMenuLayerMobile = () => {
  const navigate = useNavigate();

  return (
    <ScreenResolver
      large={BREAKPOINTS.md}
      desktop={null}
      mobile={
        <div className="top-menu-layer-mobile">
          <div
            className="top-menu-layer-mobile__button-container"
            onClick={() => navigate(PATHS.menu)}
          >
            <FontAwesomeIcon icon={faBars} className="top-menu-layer-mobile__button" />
          </div>
          <ScreenResolver
            large={BREAKPOINTS.md}
            desktop={null}
            mobile={
              <div className="top-menu-layer-mobile__title">
                <h1 className="top-menu-layer-mobile__title-front">{IETD}</h1>
              </div>
            }
          />
          <div
            className="top-menu-layer-mobile__button-container"
            onClick={() => navigate(PATHS.profile)}
          >
            <FontAwesomeIcon icon={faUser} className="top-menu-layer-mobile__button" />
          </div>
        </div>
      }
    />
  );
};

export default TopMenuLayerMobile;
