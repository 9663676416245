import React from 'react';
import { useNavigate, useLocation } from 'react-router-dom';
import { PATHS, UNPROTECTED_PATHS } from '../../../utils/constants';
import { BREAKPOINTS } from '../../../utils/breakpoints';
import ScreenResolver from '../ScreenResolver'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faBars, faUser } from '@fortawesome/free-solid-svg-icons';
import './index.scss';

const TopMenuLayer = () => {
  const navigate = useNavigate();
  const location = useLocation();

  return (
    !UNPROTECTED_PATHS.includes(`/${location.pathname.split('/')?.[1]}`)
    ? (
      <ScreenResolver
        large={BREAKPOINTS.md}
        desktop={
          <div className="top-menu-layer">
            <div
              className="top-menu-layer__button-container"
              onClick={() => navigate(PATHS.menu)}
            >
              <FontAwesomeIcon icon={faBars} className="top-menu-layer__button" />
            </div>
            <div
              className="top-menu-layer__button-container"
              onClick={() => navigate(PATHS.profile)}
            >
              <FontAwesomeIcon icon={faUser} className="top-menu-layer__button" />
            </div>
          </div>
        }
        mobile={null}
      />
    ) : null
  );
};

export default TopMenuLayer;
