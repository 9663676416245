import React from 'react';
import { useNavigate } from 'react-router-dom';
import { useIntl } from 'react-intl';
import * as classnames from 'classnames';
import { AppContext } from '../../../../storage/context';
import { PATHS, PROFILE_MENU, PROFILE_SUBPAGES } from '../../../../utils/constants';
import TopMenuLayerMobile from '../../../common/TopMenuLayerMobile';
import BackButton from '../../../common/ui/Buttons/BackButton';
import Button36 from '../../../common/ui/Buttons/Button36';
import './index.scss';

const ProfilePageMobile = props => {
  const [{ user = {} }, dispatch] = React.useContext(AppContext);

  const { className } = props;

  const intl = useIntl();
  const navigate = useNavigate();

  const nullToken = () => {
    dispatch({
      type: 'nullToken',
    });
  };

  const nullUser = () => {
    dispatch({
      type: 'nullUser',
    });
  };
  
  const logOut = () => {
    nullToken();
    sessionStorage.clear();
    localStorage.clear();
    nullUser();
    navigate(PATHS.root);
  };

  const navigateToSubpage = subRoute => () => {
    navigate(`${PATHS.profile}${subRoute}`);
  };

  return (
    <div className={classnames(
      'profile-page-mobile',
      className && className,
    )}>
      <TopMenuLayerMobile />
      <div className="profile-page-mobile__mobile-title">
        {intl.messages['profile.menu.title']}
      </div>
      <div className="profile-page-mobile__content">
        <p className="profile-page-mobile__title">
          {intl.messages['profile.menu.title']}
        </p>
        <p className="profile-page-mobile__detail">{user.name}</p>
        <p className="profile-page-mobile__detail">{user.email}</p>
        <p className="profile-page-mobile__detail">{intl.messages['profile.menu.phoneShort']}: {user.phone}</p>
        <p
          className="profile-page-mobile__edit"
          onClick={navigateToSubpage(PROFILE_MENU.find(item => item.content === PROFILE_SUBPAGES.EDIT)?.subRoute)}
        >{intl.messages['profile.menu.edit']}</p>
        <Button36
          className={classnames(
            'profile-page-mobile__button',
            '-new-primary',
          )}
          label={intl.messages['profile.menu.logout']}
          handleClick={logOut}
        />
        <div className="profile-page-mobile__options">
          {
            PROFILE_MENU.filter(item => item.content !== PROFILE_SUBPAGES.EDIT).map(menuItem => (
              <h3
                key={menuItem.order}
                className="profile-page-mobile__option"
                onClick={navigateToSubpage(menuItem.subRoute)}
              >
                {intl.messages[menuItem.labelKey]}
              </h3>
            ))
          }
        </div>
      </div>
      <div className="profile-page-mobile__bottom-section">
        <BackButton className="profile-page-mobile__back-button" />
      </div>
    </div>
  );
};

export default ProfilePageMobile;
