import React from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { useIntl } from 'react-intl';
import * as classnames from 'classnames';
import { CASE_SUBPAGES, LANGUAGE, LANGUAGES, PATHS } from '../../../../utils/constants';
import TopMenuLayerMobile from '../../../common/TopMenuLayerMobile';
import Button34 from '../../../common/ui/Buttons/Button34';
import BackButton from '../../../common/ui/Buttons/BackButton';
import CasePageMobileCase from './CasePageMobileCase';
import CasePageMobileChallenge from './CasePageMobileChallenge';
import CasePageMobileSolution from './CasePageMobileSolution';
import CasePageMobileResult from './CasePageMobileResult';
import './index.scss';

const CasePageMobile = props => {
  const { className, caseItem, navigateToCandidateList } = props;

  const intl = useIntl();
  const { caseCategorySlug, caseSlug, caseSubpageSlug } = useParams();
  const navigate = useNavigate();

  const selectedLanguageCode = localStorage.getItem(LANGUAGE) || LANGUAGES.DK;

  const renderCaseSubpages = () => {
    if (!caseItem) {
      return null;
    }
    switch (caseSubpageSlug) {
      case CASE_SUBPAGES.CASE.slug:
        return (
          <CasePageMobileCase
            className="case-page-mobile__subpage"
            caseItem={caseItem}
            navigateToCandidateList={navigateToCandidateList}
          />
        );
      case CASE_SUBPAGES.CHALLENGE.slug:
        return (
          <CasePageMobileChallenge
            className="case-page-mobile__subpage"
            caseItem={caseItem}
            navigateToCandidateList={navigateToCandidateList}
          />
        );
      case CASE_SUBPAGES.SOLUTION.slug:
        return (
          <CasePageMobileSolution
            className="case-page-mobile__subpage"
            caseItem={caseItem}
            navigateToCandidateList={navigateToCandidateList}
          />
        );
      case CASE_SUBPAGES.RESULT.slug:
        return (
          <CasePageMobileResult
            className="case-page-mobile__subpage"
            caseItem={caseItem}
            navigateToCandidateList={navigateToCandidateList}
          />
        );
      default:
        return null;
    }
  };

  return (
    <div className={classnames(
      'case-page-mobile',
      className && className,
    )}>
      <TopMenuLayerMobile />
      <div className="case-page-mobile__title">
        {caseItem?.[selectedLanguageCode].name}
      </div>
      <div className="case-page-mobile__submenu" >
        {Object.keys(CASE_SUBPAGES).map(key => {
          const subPage = CASE_SUBPAGES[key];
          
          return (
            <Button34
              key={key}
              className={classnames(
                'case-page-mobile__submenu-button',
                subPage.slug === caseSubpageSlug ? '-new-primary' : '-new-primary-light',
              )}
              label={intl.messages[subPage.translationKey]}
              handleClick={() => navigate(`${PATHS.cases}/${caseCategorySlug}/${caseSlug}/${subPage.slug}`)}
            />
          )
        })}
      </div>
      {renderCaseSubpages()}
      <div className="case-page-mobile__bottom-section">
        <div className="case-page-mobile__button-container">
          <Button34
            className="case-page-mobile__button-modify"
            label={intl.messages['candidate.cases']}
            handleClick={() => navigate(PATHS.cases)}
          />
        </div>
        <BackButton className="case-page-mobile__back-button" />
      </div>
    </div>
  );
};

export default CasePageMobile;
