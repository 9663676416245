import React from 'react';
import { useIntl } from 'react-intl';
import * as classnames from 'classnames';
import { IETD } from '../../../utils/constants';
import { BREAKPOINTS } from '../../../utils/breakpoints';
import useResolveScreen from '../../../utils/hooks/useResolveScreen';
import './index.scss';

const NoRotateModal = () => {
  const [isVisible, setIsVisible] = React.useState(false);
  const screen = useResolveScreen(BREAKPOINTS.lg);

  const intl = useIntl();

  React.useEffect(() => {
    if (screen) {
      if (screen.isMobile && screen.isLandscape) {
        setIsVisible(true);
      } else {
        setIsVisible(false);
      }
    }
  }, [screen, screen.isMobile, screen.isLandscape]);

  return (
    <div className={classnames(
      'no-rotate-modal',
      isVisible && '-visible',
    )}>
      <div className="no-rotate-modal__content">
        <p className="no-rotate-modal__title">
          <span className="no-rotate-modal__title-part -new-primary">{IETD}</span>
        </p>
        <p className="no-rotate-modal__message">
          {intl.messages['modal.message.portraitView']}
        </p>
      </div>
    </div>
  );
};

export default NoRotateModal;
