import colors from "../colors";
import { BREAKPOINTS } from "../breakpoints";

export const singleValueSelect = {
  option: (provided, state) => {
    const option = {
      ...provided,
      borderLeft: `0.19vmin solid ${colors.white}`,
      backgroundColor: state.isFocused && 'transparent',
      color: state.isFocused && colors.black,
      cursor: 'pointer',
      fontSize: '1.11vmin',
      fontWeight: '600',
      textTransform: 'uppercase',
      whiteSpace: 'nowrap',
      overflow: 'hidden',
      textOverflow: 'ellipsis',
  
      '&:hover': {
        color: colors.newTertiary,
        borderColor: colors.black,
        backgroundColor: 'transparent'
      }
    };
    option[`@media (max-width: ${BREAKPOINTS.md}px)`] = {
      fontSize: '12px',
      borderLeftWidth: '2px',
    };

    return option;
  },
  placeholder: provided => {
    const placeholder = {
      ...provided,
      fontSize: '1.11vmin',
      fontWeight: '600',
      marginLeft: '0',
      marginRight: '0',
      color: colors.black,
      textTransform: 'uppercase',
    };
    placeholder[`@media (max-width: ${BREAKPOINTS.md}px)`] = {
      fontSize: '12px',
    };

    return placeholder;
  },
  control: (provided, state) => {
    const control = {
      ...provided,
      padding: '1.44vmin 1.1vmin',
      border: `0.09vmin solid ${colors.black}`,
      borderRadius: '3.61vmin',
      borderColor: colors.black,
      boxShadow: 'none',
  
      '&:hover': {
        borderColor: colors.black,
        cursor: 'pointer',
      },
  
      '&.error': {
        borderColor: colors.red,
      },
    };
    control[`@media (max-width: ${BREAKPOINTS.md}px)`] = {
      padding: '11px 12px',
      borderWidth: '1px',
      borderRadius: '38px',
    };

    return control;
  },
  input: provided => ({
    ...provided,
    margin: '0',
    padding: '0',
  }),
  singleValue: provided => {
    const singleValue = {
      ...provided,
      fontSize: '1.11vmin',
      fontWeight: '600',
      color: colors.black,
      textTransform: 'uppercase',
    };
    singleValue[`@media (max-width: ${BREAKPOINTS.md}px)`] = {
      fontSize: '12px',
    };

    return singleValue;
  },
  menu: provided => {
    const menu = {
      ...provided,
      backgroundColor: colors.white,
      margin: '0.09vmin 0',
      overflow: 'hidden'
    };
    menu[`@media (max-width: ${BREAKPOINTS.md}px)`] = {
      margin: '1px 0',
    };

    return menu;
  },
  menuList: provided => ({
    ...provided,
    '&::-webkit-scrollbar': {
      display: 'none'
    }
  }),
  indicatorSeparator: () => ({
    display: 'none'
  }),
  clearIndicator: provided => ({
    ...provided,
    color: colors.black,
    padding: '0',

    '&:hover': {
      color: colors.newTertiary,
      cursor: 'pointer'
    }
  }),
  dropdownIndicator: (provided, { selectProps: { menuIsOpen } }) => {
    const dropdownIndicator = {
      ...provided,
      color: colors.black,
      transform: menuIsOpen && 'rotate(180deg)',
      padding: '0 1.02vmin',
      '& svg path': {
        fill: menuIsOpen && colors.newTertiary,
      },
      '&:hover': {
        color: colors.newTertiary,
        cursor: 'pointer'
      }
    };
    dropdownIndicator[`@media (max-width: ${BREAKPOINTS.md}px)`] = {
      padding: '0 11px',
    };

    return dropdownIndicator;
  },
};
