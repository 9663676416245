import React from 'react';
import { useIntl } from 'react-intl';
import * as classnames from 'classnames';
import { AppContext } from '../../../storage/context';
import { CONTACT_METHODS } from '../../../utils/constants';
import Button from '../ui/Buttons/Button';
import './index.scss';

const FullScreenPromptModal = () => {
  const [{ fullScreenPromptModalData = {} }, dispatch] = React.useContext(AppContext);

  const intl = useIntl();

  const handleOption1 = () => {
    if (fullScreenPromptModalData?.option1?.handler) {
      fullScreenPromptModalData.option1.handler(CONTACT_METHODS.PHONE);
    }
    dispatch({
      type: 'nullFullScreenPromptModalData',
    });
  };

  const handleOption2 = () => {
    if (fullScreenPromptModalData?.option2?.handler) {
      fullScreenPromptModalData.option2.handler(CONTACT_METHODS.EMAIL);
    }
    dispatch({
      type: 'nullFullScreenPromptModalData',
    });
  };

  return (
    <div className={classnames(
      'full-screen-prompt-modal',
      !fullScreenPromptModalData.titles?.length && !fullScreenPromptModalData.descriptions?.length && '-hidden',
    )}>
      <div className="full-screen-prompt-modal__container">
        <div className="full-screen-prompt-modal__backdrop"></div>
        <div className="full-screen-prompt-modal__content">
          <h2 className="full-screen-prompt-modal__title">
            {fullScreenPromptModalData.titles?.map((title, i) => (
              <span
                key={i}
                className={classnames(
                  'full-screen-prompt-modal__title-part',
                  title.className && title.className,
                )}
                dangerouslySetInnerHTML={{ __html: title.text || "" }}
              ></span>
            ))}
          </h2>
          <p className="full-screen-prompt-modal__description">
            {fullScreenPromptModalData.descriptions?.map((description, i) => (
              <span
                key={i}
                className={classnames(
                  'full-screen-prompt-modal__description-part',
                  description.className && description.className,
                )}
                dangerouslySetInnerHTML={{ __html: description.text || "" }}
              ></span>
            ))}
          </p>
          <div className="full-screen-prompt-modal__footer">
            <Button
              className={classnames(
                'full-screen-prompt-modal__button',
                '-new-tertiary',
              )}
              label={fullScreenPromptModalData?.option1?.label || intl.messages['common.ok']}
              handleClick={handleOption1}
            />
            <Button
              className={classnames(
                'full-screen-prompt-modal__button',
                '-new-tertiary',
              )}
              label={fullScreenPromptModalData?.option2?.label || intl.messages['common.cancel']}
              handleClick={handleOption2}
            />
            <Button
              className={classnames(
                'full-screen-prompt-modal__button',
                '-new-tertiary',
              )}
              label={intl.messages['common.back']}
              handleClick={() => dispatch({
                type: 'nullFullScreenPromptModalData',
              })}
            />
          </div>
        </div>
      </div>
    </div>
  );
};

export default FullScreenPromptModal;
