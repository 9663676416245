import React from 'react';
import { useNavigate } from 'react-router-dom';
import { useIntl } from 'react-intl';
import { AppContext } from '../../../storage/context';
import { PATHS, LANGUAGE, LANGUAGES, TRACKING_EVENT_NAMES } from '../../../utils/constants';
import { BREAKPOINTS } from '../../../utils/breakpoints';
import useLoadingLayer from '../../../utils/hooks/useLoadingLayer';
import useSetError from '../../../utils/hooks/useSetError';
import { postTrackingEvent } from '../../../api/services/Tracking';
import TopMenuLayerMobile from '../../common/TopMenuLayerMobile';
import ScreenResolver from '../../common/ScreenResolver';
import MenuButton from '../../common/ui/Buttons/MenuButton';
import BackButton from '../../common/ui/Buttons/BackButton';
import mainCategoryPageBackground from '../../../assets/images/backgrounds/ietd/bg01.png';
import './index.scss';

const MainCategoryPage = () => {
  const { user = {}, helpers = {} } = React.useContext(AppContext)[0];

  const navigate = useNavigate();
  const intl = useIntl();
  const loadingLayer = useLoadingLayer();
  const setError = useSetError();

  const { mainCategories, trackingEventTypes } = helpers;
  const selectedLanguageCode = localStorage.getItem(LANGUAGE) || LANGUAGES.DK;

  const navigateToSubcategories = async (navigateTo, mainCategoryId) => {
    const eventId = trackingEventTypes
      ?.find(eventType => eventType.name === TRACKING_EVENT_NAMES.MAIN_CATEGORY_CLICK)
      ?.id;

    if (user.id && eventId && mainCategoryId) {
      try {
        loadingLayer.show();

        const payload = {
          userId: user.id,
          trackingEventTypeId: eventId,
          mainCategoryId,
        };

        await postTrackingEvent(payload, setError);
      } catch (err) {
        console.error(err);
      } finally {
        loadingLayer.hide();
      }
    }

    navigate(navigateTo);
  };

  return (
    <div className="main-category-page">
      <div className="main-category-page__container">
        <TopMenuLayerMobile />
        <div className="main-category-page__mobile-title">
          {intl.messages['categoryPage.mobileTitle']}
        </div>
        <ScreenResolver
          large={BREAKPOINTS.md}
          desktop={
            <div className="main-category-page__content">
              <div className="main-category-page__text">
                <h1
                  className="main-category-page__title"
                  dangerouslySetInnerHTML={{ __html: intl.messages['categoryPage.title'] }}
                ></h1>
                <div className="main-category-page__descriptions">
                  <p
                    className="main-category-page__description"
                    dangerouslySetInnerHTML={{ __html: intl.messages["categoryPage.description1"] }}
                  ></p>
                  <p 
                    className="main-category-page__description"
                    dangerouslySetInnerHTML={{ __html: intl.messages["categoryPage.description2"] }}
                  ></p>
                </div>
              </div>
              <div className="main-category-page__menu">
                {mainCategories && mainCategories
                  .sort((category1, category2) => category1.order - category2.order)
                  .map(category => (
                    <MenuButton
                      key={category.id}
                      className="main-category-page__menu-button"
                      label={category[selectedLanguageCode].title}
                      handleClick={() => navigateToSubcategories(
                        `${PATHS.categories}/${category.slug}`,
                        category.id,
                      )}
                    />
                ))}
              </div>
            </div>
          }
          mobile={
            <div className="main-category-page__content">
              <div className="main-category-page__menu">
                <div className="main-category-page__menu-button-container">
                  {mainCategories && mainCategories
                    .sort((category1, category2) => category1.order - category2.order)
                    .map(category => (
                      <MenuButton
                        key={category.id}
                        className="main-category-page__menu-button"
                        label={category[selectedLanguageCode].title}
                        handleClick={() => navigateToSubcategories(
                          `${PATHS.categories}/${category.slug}`,
                          category.id,
                        )}
                      />
                  ))}
                </div>
              </div>
            </div>
          }
        />
        <div className="main-category-page__bottom-section">
          <BackButton className="main-category-page__back-button" />
        </div>
        <img className="main-category-page__background" src={mainCategoryPageBackground} alt="background" />
      </div>
    </div>
  );
};

export default MainCategoryPage;
