import React from 'react';
import { useIntl } from 'react-intl';
import * as classnames from 'classnames';
import { AppContext } from '../../../storage/context';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faUser } from '@fortawesome/free-solid-svg-icons';
import { API_BASE_URL, LANGUAGE, LANGUAGES } from '../../../utils/constants';
import Button34 from '../ui/Buttons/Button34';
import CloseButton from '../ui/Buttons/CloseButton';
import './index.scss';

const CandidateCardMobile = (props) => {
  const { helpers = {}, candidateSearchParams = null } = React.useContext(AppContext)[0];
  const { className, candidate, navigateToCandidate, removeCandidate } = props;

  const intl = useIntl();
  const { tools, skills } = helpers;
  const selectedLanguageCode = localStorage.getItem(LANGUAGE) || LANGUAGES.DK;

  const searchedToolIds = candidateSearchParams?.tools.filter(tool => !!tool).map(tool => tool.value) || [];
  const skillIds = candidateSearchParams?.skills.filter(skill => skill.isChecked).map(skill => skill.groupId) || [];
  const allSkillIds = candidateSearchParams?.allSkills.map(skill => skill.value) || [];
  const searchedSkillIds = [...new Set(skillIds.concat(allSkillIds))];

  const sortTools = originalTools => {
    return originalTools
      .map(tool => tools?.find(toolEntity => toolEntity.id === tool.toolId))
      .sort((tool1, tool2) => tool2.level - tool1.level)
      .sort((tool1, tool2) => {
        if (searchedToolIds.includes(tool2.groupId) && !searchedToolIds.includes(tool1.groupId)) {
          return 1;
        }
        if (!searchedToolIds.includes(tool2.groupId) && searchedToolIds.includes(tool1.groupId)) {
          return -1;
        }
        return 0;
      });
  };

  const sortSkills = originalSkillIds => {
    return originalSkillIds
      .map(skillId => skills?.find(skill => skill.id === skillId))
      .sort((skill1, skill2) => skill2.level - skill1.level)
      .sort((skill1, skill2) => {
        if (searchedSkillIds.includes(skill2.groupId) && !searchedSkillIds.includes(skill1.groupId)) {
          return 1;
        }
        if (!searchedSkillIds.includes(skill2.groupId) && searchedSkillIds.includes(skill1.groupId)) {
          return -1;
        }
        return 0;
      });
  };

  return (
    <div className={classnames(
      'candidate-card-mobile',
      className && className,
    )}>
      <div className="candidate-card-mobile__top">
        <div className="candidate-card-mobile__avatar-section">
          <div className="candidate-card-mobile__avatar-section-left">
            <div className="candidate-card-mobile__avatar-container">
              {candidate.avatar ? (
                <div className="candidate-card-mobile__avatar">
                  <img className="candidate-card-mobile__avatar-image" src={`${API_BASE_URL}${candidate.avatar}`} alt={candidate.profileName} />
                </div>
              ) : (
                <div className="candidate-card-mobile__avatar-placeholder">
                  <FontAwesomeIcon icon={faUser} className="candidate-card-mobile__avatar-placeholder-image" />
                </div>
              )}
            </div>
          </div>
        </div>
        <div className="candidate-card-mobile__details-section">
          <p
            className="candidate-card-mobile__profile-number"
            dangerouslySetInnerHTML={{ __html: candidate.profileNumber }}
          ></p>
          <p
            className="candidate-card-mobile__profile-title"
            dangerouslySetInnerHTML={{ __html: candidate[selectedLanguageCode].profileTitle }}
          ></p>
          <div className="candidate-card-mobile__tools">
            {sortTools(candidate.tools)
              .map((tool, index) => (
                index < 2 && (
                  <div key={index} className="candidate-card-mobile__tool">
                    <p
                      className="candidate-card-mobile__tool-name"
                      dangerouslySetInnerHTML={{ __html: tool?.name }}
                    ></p>
                  </div>
                )
            ))}
          </div>
          <div className="candidate-card-mobile__skills">
            {sortSkills(candidate.skillIds)
              .map((skill, index) => (
                index < 3 && (
                  <div key={index} className="candidate-card-mobile__skill">
                    <p
                      className="candidate-card-mobile__skill-name"
                      dangerouslySetInnerHTML={{ __html: skill?.[selectedLanguageCode].name }}
                    ></p>
                  </div>
                )
            ))}
          </div>
        </div>
      </div>
      <div className="candidate-card-mobile__bottom">
        <div
          className="candidate-card-mobile__profile-description"
          dangerouslySetInnerHTML={{ __html: candidate[selectedLanguageCode].profileDescription }}
        />
        <Button34
          className='candidate-card-mobile__button -new-primary'
          label={intl.messages['common.goTo']}
          handleClick={() => navigateToCandidate(candidate.id)}
        />
      </div>
      {removeCandidate ? (
        <CloseButton
          className="candidate-card-mobile__close-button"
          handleClick={() => removeCandidate(candidate.id)}
        />
      ) : null}
    </div>
  );
};

export default CandidateCardMobile;
