import React from 'react';
import { useNavigate } from "react-router-dom";
import { useIntl } from 'react-intl';
import { Formik } from 'formik';
import { loginSchema } from '../../../utils/validationSchemas';
import InputTextField from '../../common/ui/InputTextField';
import Button from '../../common/ui/Buttons/Button';

const LoginForm = (props) => {
  const { onSubmit } = props;

  const intl = useIntl();

  const initialValues = {
    email: '',
    password: ''
  };

  const ERROR_MESSAGES = {
    EMAIL_EMPTY: intl.messages['error.emailEmpty'],
    EMAIL_FORMAT: intl.messages['error.emailInvalid'],
    PASSWORD_EMPTY: intl.messages['error.passwordEmpty'],
    PASSWORD_FORMAT: intl.messages['error.passwordInvalid'],
    PASSWORD_SHORT: intl.messages['error.passwordShort'],
    REQUIRED_FIELD: intl.messages['error.fieldRequired'],
    ACCEPT_TERMS_REQUIRED: intl.messages['error.acceptTerms'],
  };

  return (
    <Formik
      initialValues={initialValues}
      validationSchema={loginSchema(ERROR_MESSAGES)}
      onSubmit={(values, formikBag) => onSubmit(values, formikBag)}
    >
      {({ values, touched, errors, handleChange, handleSubmit, handleBlur, isSubmitting }) => (
        <form className="login-page__form" onSubmit={handleSubmit}>
          <InputTextField
            className="login-page__form-input"
            placeholder={intl.messages['login.email']}
            name="email"
            value={values.email}
            onChange={handleChange}
            onBlur={handleBlur}
            error={touched.email && errors.email}
          />
          <InputTextField
            className="login-page__form-input"
            placeholder={intl.messages['login.password']}
            name="password"
            value={values.password}
            onChange={handleChange}
            onBlur={handleBlur}
            type="password"
            error={touched.password && errors.password}
          />

          <div className="login-page__buttons">
            <Button
                className='login-page__button -login'
                label={intl.messages['login.login']}
                type='submit'
                onSubmit={handleSubmit}
                disabled={isSubmitting || Object.keys(errors).length}
            />
          </div>
        </form>
      )}
    </Formik>
  );
}

export default LoginForm;
