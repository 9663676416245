import React from 'react';
import { useIntl } from 'react-intl';
import { AppContext } from '../../../storage/context';
import { PATHS, LANGUAGE, LANGUAGES } from '../../../utils/constants';
import { BREAKPOINTS } from '../../../utils/breakpoints';
import ScreenResolver from '../../common/ScreenResolver';
import TopMenuLayerMobile from '../../common/TopMenuLayerMobile';
import MenuButton from '../../common/ui/Buttons/MenuButton';
import BackButton from '../../common/ui/Buttons/BackButton';
import caseCategoryPageBackground from '../../../assets/images/backgrounds/ietd/bg_aca_01.png';
import './index.scss';

const CaseCategoryPage = () => {
  const { helpers = {} } = React.useContext(AppContext)[0];
  
  const intl = useIntl();

  const { caseCategories } = helpers;
  const selectedLanguageCode = localStorage.getItem(LANGUAGE) || LANGUAGES.DK;

  return (
    <div className="case-category-page">
      <div className="case-category-page__container">
        <TopMenuLayerMobile />
        <div className="case-category-page__mobile-title">
          {intl.messages['common.cases']}
        </div>
        <ScreenResolver
          large={BREAKPOINTS.md}
          desktop={
            <div className="case-category-page__content">
              <div className="case-category-page__text">
                <h1
                  className="case-category-page__title"
                  dangerouslySetInnerHTML={{ __html: intl.messages['caseCategory.title'] }}
                ></h1>
                <div className="case-category-page__descriptions">
                  <p
                    className="case-category-page__description"
                    dangerouslySetInnerHTML={{ __html: intl.messages["caseCategory.description1"] }}
                  ></p>
                  <p 
                    className="case-category-page__description"
                    dangerouslySetInnerHTML={{ __html: intl.messages["caseCategory.description2"] }}
                  ></p>
                </div>
              </div>
              <div className="case-category-page__menu">
                {caseCategories && caseCategories
                  .sort((category1, category2) => category1.order - category2.order)
                  .map(category => (
                    <MenuButton
                      key={category.id}
                      className="case-category-page__menu-button"
                      label={category[selectedLanguageCode]?.name}
                      navigateTo={`${PATHS.cases}/${category.slug}`}
                    />
                  ))}
              </div>
            </div>
          }
          mobile={
            <div className="case-category-page__content">
              <div className="case-category-page__menu">
                <div className="case-category-page__menu-button-container">
                  {caseCategories && caseCategories
                    .sort((category1, category2) => category1.order - category2.order)
                    .map(category => (
                      <MenuButton
                        key={category.id}
                        className="case-category-page__menu-button"
                        label={category[selectedLanguageCode]?.name}
                        navigateTo={`${PATHS.cases}/${category.slug}`}
                      />
                    ))}
                </div>
              </div>
            </div>
          }
        />
        <div className="case-category-page__bottom-section">
          <BackButton className="case-category-page__back-button" />
        </div>
        <img className="case-category-page__background" src={caseCategoryPageBackground} alt="background" />
      </div>
    </div>
  );
};

export default CaseCategoryPage;
