import React from 'react';
import { useParams, useNavigate } from 'react-router-dom';
import { useIntl } from 'react-intl';
import * as classnames from 'classnames';
import { CASE_SUBPAGES, LANGUAGE, LANGUAGES, PATHS } from '../../../../utils/constants';
import { AppContext } from '../../../../storage/context';
import CasePageDesktopCase from './CasePageDesktopCase';
import CasePageDesktopChallenge from './CasePageDesktopChallenge';
import CasePageDesktopSolution from './CasePageDesktopSolution';
import CasePageDesktopResult from './CasePageDesktopResult';
import MenuButton from '../../../common/ui/Buttons/MenuButton';
import BackButton from '../../../common/ui/Buttons/BackButton';
import Button45 from '../../../common/ui/Buttons/Button45';
import SubmenuButton from '../../../common/ui/Buttons/SubmenuButton';
import './index.scss';

const CasePageDesktop = props => {
  const { helpers = {} } = React.useContext(AppContext)[0];

  const { className, caseItem, navigateToCandidateList } = props;

  const intl = useIntl();
  const { caseCategorySlug, caseSlug, caseSubpageSlug } = useParams();
  const navigate = useNavigate();
  const { caseCategories, cases } = helpers;

  const selectedLanguageCode = localStorage.getItem(LANGUAGE) || LANGUAGES.DK;
  const caseCategory = React.useMemo(() => {
    return caseCategories?.find(category => category.slug === caseCategorySlug);
  }, [caseCategories, caseCategorySlug]);

  const renderCaseSubpages = () => {
    if (!caseItem) {
      return null;
    }
    switch (caseSubpageSlug) {
      case CASE_SUBPAGES.CASE.slug:
        return (
          <CasePageDesktopCase
            caseItem={caseItem}
            navigateToCandidateList={navigateToCandidateList}
          />
        );
      case CASE_SUBPAGES.CHALLENGE.slug:
        return (
          <CasePageDesktopChallenge
            caseItem={caseItem}
            navigateToCandidateList={navigateToCandidateList}
          />
        );
      case CASE_SUBPAGES.SOLUTION.slug:
        return (
          <CasePageDesktopSolution
            caseItem={caseItem}
            navigateToCandidateList={navigateToCandidateList}
          />
        );
      case CASE_SUBPAGES.RESULT.slug:
        return (
          <CasePageDesktopResult
            caseItem={caseItem}
            navigateToCandidateList={navigateToCandidateList}
          />
        );
      default:
        return null;
    }
  };

  return (
    <div className={classnames(
      'case-page-desktop',
      className && className,
    )}>
      <div className="case-page-desktop__body">
        {renderCaseSubpages()}
        <div className="case-page-desktop__menu">
          {caseCategory?.cases && caseCategory.cases.map(caseId => {
            const caseItem = cases.find(caseItem => caseItem.id === caseId);
            return (
              <MenuButton
                key={caseItem?.id}
                className={classnames(
                  'case-page-desktop__menu-button',
                  caseItem.slug === caseSlug && '-tertiary',
                )}
                label={caseItem?.[selectedLanguageCode].name}
                navigateTo={`${PATHS.cases}/${caseCategory.slug}/${caseItem.slug}/${CASE_SUBPAGES.CASE.slug}`}
              />
            );
          })}
        </div>
      </div>
      <div className="case-page-desktop__footer">
        <div className="case-page-desktop__submenu">
          {Object.keys(CASE_SUBPAGES).map((key, i) => {
            const subPage = CASE_SUBPAGES[key];
            return (
              <SubmenuButton
                key={key}
                className={classnames(
                  'case-page-desktop__submenu-button',
                  subPage.slug === caseSubpageSlug ? '-new-primary' : '-new-primary-light',
                )}
                label={intl.messages[subPage.translationKey]}
                handleClick={() => navigate(`${PATHS.cases}/${caseCategorySlug}/${caseSlug}/${subPage.slug}`)}
              />
            );
          })}
        </div>
        <Button45
          className="case-page-desktop__button-cases -new-tertiary"
          label={intl.messages['common.cases']}
          handleClick={() => navigate(PATHS.cases)}
        />
      </div>
    <BackButton />
    </div>
  );
};

export default CasePageDesktop;
