import React from 'react';
import { useIntl } from 'react-intl';
import Slider from 'react-slick';
import { API_BASE_URL, LANGUAGE, LANGUAGES } from '../../../../../utils/constants';
import useSliderSettings from '../../hooks';
import Button45 from '../../../../common/ui/Buttons/Button45';
import palceholderBackground from '../../../../../assets/images/backgrounds/placeholder.png';
import './index.scss';

const CasePageDesktopChallenge = props => {
  const { caseItem, navigateToCandidateList } = props;

  const intl = useIntl();
  const sliderSettings = useSliderSettings();

  const selectedLanguageCode = localStorage.getItem(LANGUAGE) || LANGUAGES.DK;

  return (
    <div className="case-page-desktop-challenge">
      <div className="case-page-desktop-challenge__content">
        <h2
          className="case-page-desktop-challenge__title"
          dangerouslySetInnerHTML={{ __html: caseItem[selectedLanguageCode].challengeHeader }}
        ></h2>
        <Button45
          className="case-page-desktop-challenge__button -black"
          label={intl.messages['case.seeCandidates']}
          handleClick={navigateToCandidateList}
          disabled={!caseItem.candidates?.length}
        />
        <p
          className="case-page-desktop-challenge__description"
          dangerouslySetInnerHTML={{ __html: caseItem[selectedLanguageCode].challengeDescription }}
        ></p>
      </div>
      <div className="case-page-desktop-challenge__gallery">
        {caseItem.caseImages?.length ? (
          <Slider { ...sliderSettings.desktop }>
            {caseItem.challengeImages?.map((image, i) => (
              <div key={i} className="case-page-desktop-challenge__image-container">
                <img
                  className="case-page-desktop-challenge__image"
                  src={`${API_BASE_URL}/${image.url}` || palceholderBackground}
                  alt="case"
                />
              </div>
            ))}
          </Slider>
        ) : (
          null
        )}
      </div>
    </div>
  );
};

export default CasePageDesktopChallenge;
